@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@100..900&display=swap");

// @font-face {
//   font-family: DESIGNER;
//   src: url("./assets/fonts/designerfont/DESIGNER.otf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: GoBold;
//   src: url("./assets/fonts/Gobold-Lowplus/Gobold\ Thin.otf");
//   // font-weight: bold;
//   font-style: normal;
// }

$secondFont: "Big Shoulders Stencil Display", sans-serif;

html {
  --wcm-color-fg-1: initial;
  --wcm-color-fg-2: initial;
  --wcm-color-fg-3: initial;
  --wcm-color-bg-1: initial;
  --wcm-color-bg-2: initial;
  --wcm-color-bg-3: initial;
  --wcm-color-overlay: initial;
  --wcm-accent-color: initial;
  --wcm-accent-fill-color: initial;
  --wcm-z-index: initial;
  --wcm-background-color: initial;
  --wcm-background-border-radius: initial;
  --wcm-container-border-radius: initial;
  --wcm-wallet-icon-border-radius: initial;
  --wcm-wallet-icon-large-border-radius: initial;
  --wcm-wallet-icon-small-border-radius: initial;
  --wcm-input-border-radius: initial;
  --wcm-button-border-radius: initial;
  --wcm-notification-border-radius: initial;
  --wcm-secondary-button-border-radius: initial;
  --wcm-icon-button-border-radius: initial;
  --wcm-button-hover-highlight-border-radius: initial;
  --wcm-text-big-bold-size: initial;
  --wcm-text-big-bold-weight: initial;
  --wcm-text-big-bold-line-height: initial;
  --wcm-text-big-bold-letter-spacing: initial;
  --wcm-text-big-bold-text-transform: initial;
  --wcm-text-xsmall-bold-size: initial;
  --wcm-text-xsmall-bold-weight: initial;
  --wcm-text-xsmall-bold-line-height: initial;
  --wcm-text-xsmall-bold-letter-spacing: initial;
  --wcm-text-xsmall-bold-text-transform: initial;
  --wcm-text-xsmall-regular-size: initial;
  --wcm-text-xsmall-regular-weight: initial;
  --wcm-text-xsmall-regular-line-height: initial;
  --wcm-text-xsmall-regular-letter-spacing: initial;
  --wcm-text-xsmall-regular-text-transform: initial;
  --wcm-text-small-thin-size: initial;
  --wcm-text-small-thin-weight: initial;
  --wcm-text-small-thin-line-height: initial;
  --wcm-text-small-thin-letter-spacing: initial;
  --wcm-text-small-thin-text-transform: initial;
  --wcm-text-small-regular-size: initial;
  --wcm-text-small-regular-weight: initial;
  --wcm-text-small-regular-line-height: initial;
  --wcm-text-small-regular-letter-spacing: initial;
  --wcm-text-small-regular-text-transform: initial;
  --wcm-text-medium-regular-size: initial;
  --wcm-text-medium-regular-weight: initial;
  --wcm-text-medium-regular-line-height: initial;
  --wcm-text-medium-regular-letter-spacing: initial;
  --wcm-text-medium-regular-text-transform: initial;
  --wcm-font-family: initial;
  --wcm-font-feature-settings: initial;
  --wcm-success-color: initial;
  --wcm-error-color: initial;
  --wcm-overlay-background-color: initial;
  --wcm-overlay-backdrop-filter: initial;
}


body {
  font-family: "Big Shoulders Display", sans-serif;
  font-optical-sizing: auto;
  background-color: #020002;
  @media screen and (min-width: 1299px) {
    background-image: url("./assets/REV\ World.gif");
    background-position: bottom -55vh left;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40%;
      height: 100%;
      background: rgb(0, 0, 0, 0.5);
    }
  }
  .App {
    min-height: 100vh;
    
  }
  header {
    position: relative;
    z-index: 9;
    // background: rgba(0, 0, 0, 0.2);
    // backdrop-filter: blur(8.800000190734863px);
    .navBtns {
      gap: 23px;
      li {
        button {
          @media screen and (max-width: 992px) {
            width: 100%;
            justify-content: center;
            font-size: 18px;
          }
        }
      }
    }
    .navbar-brand {
      svg {
        @media screen and (max-width: 767px) {
          max-width: 180px;
        }
      }
    }
    .navbar-light {
      .navbar-toggler {
        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 15 15'%3E%3Cpath fill='white' d='M3.64 2.27L7.5 6.13l3.84-3.84A.92.92 0 0 1 12 2a1 1 0 0 1 1 1a.9.9 0 0 1-.27.66L8.84 7.5l3.89 3.89A.9.9 0 0 1 13 12a1 1 0 0 1-1 1a.92.92 0 0 1-.69-.27L7.5 8.87l-3.85 3.85A.92.92 0 0 1 3 13a1 1 0 0 1-1-1a.9.9 0 0 1 .27-.66L6.16 7.5L2.27 3.61A.9.9 0 0 1 2 3a1 1 0 0 1 1-1c.24.003.47.1.64.27'/%3E%3C/svg%3E");
          width: 28px;
          height: 28px;
        }
        &.collapsed {
          .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 17h18M3 12h18M3 7h18'/%3E%3C/svg%3E");
            width: 38px;
            height: 38px;
          }
        }
      }
    }
  }
  @media screen and (max-width:992px) {
    .navbar-collapse{
      ul{
        padding-bottom: 10px;
        padding-top: 10px;
        transition: all 0.3s ease;
      }
      &.collapse{
        &.show{
          // padding-bottom: 80px;
          ul{
            // min-height: calc(100vh - 190px);
            // padding: 0 80px;
            justify-content: center;
          }
        }
      }
    }
  }
  .mobilrMetaMask {
    text-align: center;
    &.disclaimer{
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
  .tokenLogoTextDiv {
    @media screen and (min-width: 992px) {
      min-width: 250px;
    }
  }
  .tokenLogoText {
    font-size: 49px;
    font-family: GoBold;
    color: #85e4be;
    text-shadow:
      0 0 5px #85e4be,
      0 0 5px #85e4be,
      0 0 10px #85e4be,
      2px 2px 1px rgba(133, 228, 190, 0);
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    /* margin: 0 auto; Gives that scrolling effect as the typing happens */
    margin-right: auto;
    letter-spacing: 0.15em; /* Adjust as needed */
    animation:
      typing1 2.2s steps(11, end) infinite,
      wait 3s;
    animation-iteration-count: infinite; /* Infinite loop */
    animation-fill-mode: forwards; /* Retain the final state */

    @keyframes typing1 {
      0% {
        width: 0;
      }
      90% {
        width: 100%;
      }
      100% {
        width: 100%;
      }
    }

    @keyframes wait {
      0%,
      95% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 39px;
    }
  }
  .tokenLogoImgDiv{
    max-width: 65px;
  }
  .btnFlexGap{
    gap: 41px;
    @media screen and (max-width:'767px') {
      gap: 16px;
    }
  }
  .tokenContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    .tokenCard {
      flex: 1;
      @media screen and (min-width: 1024px) {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(100vh - 120px);
        &::after {
          content: "";
          position: absolute;
          top: -8%;
          right: 0%;
          height: 108%;
          width: 100%;
          z-index: -2;
          // background: linear-gradient(90deg, #010001 0%, rgba(1, 0, 1, 0) 100%);
          background-image: url("./assets/REVlazyLoad.webp");
          background-position: center;
          background-size: 120%;
          background-repeat: no-repeat;
          @media screen and (min-width:1799px) {
            top: -10%;
            background-size: 111%;
          }
          @media screen and (min-width:1999px) {
            top: -3%;
            background-size: 111%;
            height: 100%;
          }
        }
        &.background-loaded{
          &::after {
            background-image: url("./assets/REV.webp");
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 100%;
          height: 100%;
          transform: translateX(50%);
          width: 150px;
          background: linear-gradient(90deg, #010001 0%, rgba(1, 0, 1, 0) 100%);
          background-size: 110%;
        }
      }
      @media screen and (min-width: 1299px) {
        min-height: calc(100vh - 92px);
      }
      @media screen and (min-width: 1399px) {
        min-height: calc(100vh - 136px);
      }
      @media screen and (max-width: 1399px) {
        padding-right: 15px;
      }
      @media screen and (max-width: 1199px) {
        padding-right: 11px;
      }
      @media screen and (max-width: 1060px) {
        background: url("./assets/REV.webp");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      // @media screen and (min-width:1254px) and (max-width:1699px) {
      //   background-size: contain;
      // }
      @media screen and (max-width: 767px) {
        padding: 0 20px 30px 20px;
      }
    }
    .tokenBox {
      border-radius: 28.685px;
      // border: 1.062px solid #49C3FB;
      // background: url('./assets/REV\ 2\ MJ.gif');
      // background-size: 150px;
      background-position: center top;
      background-repeat: no-repeat;
      margin: 0 auto;
      position: relative;
      padding: 0 35px 30px 35px;
      // padding-top: 150px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 560px;
      @media screen and (min-width: 767px) {
        min-width: 520px;
      }
      z-index: 1;
      backdrop-filter: blur(14.6611967086792px);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(73, 195, 251, 0.1);
        border: 1.062px solid #49c3fb;
        border-radius: 28.685px;
        z-index: -1;
      }
      .tokenLogo {
        // max-width: 216px;
        margin: auto;
        width: 100%;
        text-align: center;

        .tokenLogoImg {
          padding-top: 35px;
          padding-bottom: 10px;
          font-size: 49px;
          font-family: GoBold;
          color: #85e4be;
          text-shadow:
            0 0 5px #85e4be,
            0 0 5px #85e4be,
            0 0 10px #85e4be,
            2px 2px 1px rgba(133, 228, 190, 0);
          overflow: hidden; /* Ensures the content is not revealed until the animation */
          white-space: nowrap; /* Keeps the content on a single line */
          margin: 0 auto; /* Gives that scrolling effect as the typing happens */
          letter-spacing: 0.15em; /* Adjust as needed */
          animation: typing 2s steps(15, end) infinite;
          @keyframes typing {
            from {
              width: 0;
            }
            to {
              width: 100%;
            }
          }
          @media screen and (max-width: 767px) {
            font-size: 32px;
            padding-top: 1px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        padding: 15px;
        border-radius: 32px;
        .tokenLogo {
          // max-width: 121px;
          margin: auto;
          svg {
            width: 121px;
            height: 121px;
          }
          img{
            max-height: 152px;
            padding: 0 0 10px 0 !important;
          }
        }
      }
      .tokenHeading {
        text-align: center;
        gap: 16px;
        @media screen and (max-width:767px) {
          gap: 10px;
        }
        .tokenName {
          color: #ffffff;
          font-size: 24px;
          font-weight: 800;
          line-height: 35.28px;
        }
        .tokenMain {
          color: #49c3fb;
          font-size: 48px;
          font-weight: 700;
          line-height: 65.6px;
          @media screen and (max-width: 767px) {
            font-size: 37px;
            font-weight: 700;
            line-height: 52.6px;
          }
        }
        span {
          color: #fff;
          font-size: 14px;
          letter-spacing: 0.5px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          opacity: 0.8;
        }
      }
      .tokenStage {
        text-align: center;
        .tokenStageName {
          color: #ffffff;
          font-size: 26px;
          font-weight: 500;
          line-height: 28.24px;
          @media screen and (max-width:767px) {
            font-size: 18px;
            line-height: 20px;
          }
        }
        .tokenBoxBg {
          border-radius: 4.25px;
          border: 0.804px solid rgba(73, 195, 251, 0.24);
          background: #03080c;
          &.tokenBgWidth {
            @media screen and (min-width: 767px) {
              max-width: calc(100% - 340px);
            }
          }
          .barBox {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
            padding: 11px;
            @media screen and (max-width: 767px) {
              gap: 10px;
              padding: 5px 0px 0px;
            }
            .heading {
              margin: 0;
              color: #ffffff;
              font-size: 16px;
              letter-spacing: 0.5px;
              font-weight: 500;
              line-height: normal;
              @media screen and (max-width: 767px) {
                font-size: 12px;
              }
            }

            .amount {
              color: #49c3fb;
              font-size: 24px;
              font-weight: 500;
              line-height: 35.28px;
              word-break: break-all;
              @media screen and (max-width: 767px) {
                font-size: 18px;
                font-weight: 700;
                line-height: 32.6px;
              }
            }
          }
          .progressBar {
            margin-top: 20px;
            background: #6a6c6d;
            height: 8px;
            border-radius: 8px;
            @media screen and (max-width:767px) {
              margin-top: 24px;
            }
            .progressAmount {
              background: #49c3fb;
              border-radius: 8px;
              position: relative;
              height: 100%;
              .percentage {
                padding: 1px 3px;
                position: absolute;
                left: 100%;
                bottom: calc(100% + 5px);
                color: #000;
                background: #49c3fb;
                font-family: "Big Shoulders Display";
                transform: translateX(-50%);
                font-size: 12.632px;
                font-style: normal;
                font-weight: 500;
                border-radius: 2px;
                line-height: 120.738%; /* 15.252px */
                &::after {
                  content: "";
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  border-top: 5px solid #49c3fb;
                  top: 100%;
                  left: 50%;
                  transform: translateX(-50%);
                }
                &.bigger {
                  &::after {
                    top: 100%;
                    left: 100%;
                    transform: translateX(-100%);
                  }
                }
              }
            }
          }
          .countDownDiv {
            text-align: center;
            .heading {
              color: #ffffff;
              font-size: 18px;
              font-weight: 400;
              line-height: 17.2px;
            }
            .countdown {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              gap: 5px;
              .timeStamp {
                color: #49c3fb;
                font-size: 33px;
                font-weight: 500;
                line-height: 35.25px;
                display: grid;
                @media screen and (max-width: 767px) {
                  font-size: 34px;
                  line-height: 43.25px;
                }
                span {
                  color: #ffffff;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20.16px;
                }
              }
            }
          }
          .tokenAmount {
            display: flex;
            justify-content: start;
            align-items: center;
            .amountInputDiv {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              gap: 7px;
              width: 100%;
              padding: 7px 16px;
              input {
                background: transparent;
                border: none;
                width: 100%;
                max-width: 100%;
                outline: none;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                line-height: 20.28px;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                -moz-appearance: textfield;
              }
              span {
                font-size: 12px;
                line-height: 100%;
                opacity: 0.5;
                color: #ffffff;

                @media screen and (max-width: 767px) {
                  display: none;
                }
              }
              @media screen and (max-width:767px) {
                padding: 13px 12px;
                input{
                  font-size: 16px;
                }
              }
            }
            .amountInUsdt {
              color: #49c3fb;
              font-size: 24px;
              font-weight: 700;
              line-height: 35.28px;
              margin: 0;
              text-align: center;
              max-width: 100%;
              overflow: hidden;
            }
            .tokenChainName {
              // min-width: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              height: 100%;
              padding: 21px 12px;
              border-radius: 2px;
              background: rgb(217, 217, 217, 0.1);
              svg {
                width: 25px;
                height: 25px;
              }
              @media screen and (max-width:767px) {
                padding: 13px 13px 13px 9px;
              }
            }
          }
        }
      }
    }
    .mobileAmount {
      display: none;
      @media screen and (max-width: 767px) {
        display: inline;
        color: #fff;
        opacity: 1;
        font-size: 14px;
        font-weight: 500;
        line-height: 18.28px;
      }
    }
    .addRev {
      margin-bottom: 0;
      color: #49c3fb;
      font-size: 14px;
      letter-spacing: 0.5px;
      font-weight: 600;
      line-height: 24.15px;
      cursor: pointer;
      span {
        border-radius: 50%;
        background: #49c3fb;
        margin-right: 8px;
        color: #000;
        display: inline;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        padding: 0px 4px;
        @media screen and (max-width:767px) {
          margin-right: 4px;
        }
      }
      @media screen and (max-width:767px) {
        font-size: 12px;
      }
    }
  }
  .bottomMarginDiv{
    margin-top: 14px;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  .customContainer {
    max-width: calc(100% - 20px);
    margin: 0 auto;
    padding: 30px 0;
    &.navbar{
      @media screen and (min-width: 1400px) {
        padding: 30px 0 30px 125px;
      }
    }
    &.tokenContainer{
      @media screen and (min-width: 1400px) and (max-width:1499px) {
        padding: 30px 0 30px 125px;
      }
      @media screen and (min-width: 1500px) {
        padding: 0 0 0 125px;
      }
    }
    @media screen and (min-width: 1400px) {
      max-width: 1920px;
    }
    @media screen and (min-width: 1700px) {
      max-width: 100%;
      padding: 30px 0 30px 145px;
      &.navbar-expand-lg {
        padding: 30px 145px 30px 145px;
      }
    }
    @media screen and (min-width: 1999px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  .customOuterContainer {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    @media screen and (min-width: 1061px) {
      &::before {
        // content: "";
        position: absolute;
        top: 55px;
        right: 0;
        height: calc(100% - 55px);
        width: 54%;
        background: linear-gradient(90deg, #010001 0%, rgba(1, 0, 1, 0) 100%);
        background-image: url("./assets/REV.gif");
        background-position: center;
        background-size: 115%;
        background-repeat: no-repeat;
      }
    }
  }
  .customOutlineBtn {
    color: #49c3fb;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px 29px;
    border-radius: 47px;
    border: 1px solid #49c3fb;
    outline: none;
    box-shadow: none;
    background: transparent;
    &.dropBtn {
      position: relative;
      padding-right: 45px;
      &::after {
        position: absolute;
        content: "";
        top: 0%;
        right: 20px;
        height: 100%;
        width: 15px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M19 8.5H5L12 19.5L19 8.5Z' fill='%2349C3FB'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    @media screen and (max-width:767px) {
      padding: 9px 16px;
      font-size: 16px;
    }
  }
  .colorBtn {
    background: #49c3fb;
    padding: 15px 28px;
    border-radius: 47px;
    font-size: 22px;
    font-weight: 600;
    line-height: 28.24px;
    color: #fff;
    border: none;
    max-width: 400px;
    &:disabled {
      background: #49c3fb75;
      padding: 15px 28px;
      border-radius: 47px;
      font-size: 22px;
      font-weight: 600;
      line-height: 28.24px;
      border: none;
      color: #ffffff8a;
      max-width: 400px;
      cursor: not-allowed;
    }
  }
  .linkClass {
    color: #49c3fb;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.15px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #49c3fb;
    }
    @media screen and (max-width:767px) {
      font-size: 16px;
    }
  }
  .socialIcons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 48px;
    margin-top: 15px;
    @media screen and (max-width: 767px) {
      gap: 28px;
      margin-top: 10px;
      svg{
        width: 32px;
        height: 32px;
      }
    }
  }
  .socialLinks {
    border-radius: 50%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &.whiteBg {
      background: #fff;
    }
  }
  .loaderDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999999;
    background: #000000c7;
    p {
      margin-top: 15px;
      font-size: 22px;
      font-weight: 500;
      line-height: 30.24px;
      color: #ffffff;
    }
  }
  .customTooltip {
    position: relative;
    display: inline-block;
    cursor: auto;
    .tooltipText {
      visibility: hidden;
      width: max-content;
      @media screen and (max-width: 767px) {
        width: auto;
      }
      color: #fff;
      background: linear-gradient(
        90deg,
        hsla(220, 27%, 2%, 0.731) 0%,
        hsla(220, 27%, 2%, 0.833) 51%,
        hsla(220, 27%, 2%, 0.731) 100%
      );
      backdrop-filter: blur(21.7999992371px);
      padding: 15px;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover {
      .tooltipText {
        visibility: visible;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 20px;
        height: 20px;
        background: linear-gradient(
          135deg,
          hsla(0, 0%, 0%, 0) 0%,
          hsla(220, 27%, 2%, 0.833) 51%,
          hsla(220, 27%, 2%, 0.731) 100%
        );
        backdrop-filter: blur(21.7999992371px);
      }
    }
  }
  .customFlex {
    display: flex;
    gap: 45px;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 992px) {
      justify-content: center;
    }
  }
  .tokenContentInfo {
    position: relative;
    z-index: 1;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 20px;
      overflow: hidden;
    }
    .mainHeadingImgDiv {
      max-width: 666px;
      @media screen and (max-width: 1699px) {
        max-width: 599px;
      }
      @media screen and (max-width: 1499px) {
        max-width: 566px;
      }
      @media screen and (max-width: 1299px) {
        max-width: 499px;
      }
      @media screen and (max-width: 1099px) {
        max-width: 407px;
        margin: auto;
      }
      @media screen and (max-width: 992px) {
        margin-top: 65px;
      }
    }
    .mainHeadingImg {
      max-width: 100%;
      object-fit: contain;
      padding-bottom: 18px;
    }
    .mainHeading {
      position: relative;
      padding-bottom: 117px;
      h2 {
        margin-bottom: 0;
        background: linear-gradient(90deg, #18dba0 0%, #3ac2da 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: DESIGNER;
        font-size: clamp(4.5rem, 1.8128rem + 5.6057vw, 7.7688125rem);
        font-style: normal;
        font-weight: 400;
        line-height: 80%;
        @media screen and (max-width: 1499px) {
          white-space: nowrap;
        }
      }
      .headingShadow {
        span {
          font-family: DESIGNER;
          font-size: clamp(4.5rem, 1.8128rem + 5.6057vw, 7.7688125rem);
          font-style: normal;
          line-height: 80%;
          font-weight: 400;
          background: linear-gradient(90deg, #18dba0 0%, #3ac2da 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          top: 18px;
          @media screen and (max-width: 1499px) {
            white-space: nowrap;
          }
        }
        // display: inline;
        position: relative;
        // bottom: 20px;
        -moz-transform: scale(1, -1);
        -webkit-transform: scale(1, -1);
        -o-transform: scale(1, -1);
        -ms-transform: scale(1, -1);
        transform: scale(1, -1);
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          // bottom: 3px;
          // @media screen and (max-width:1499px) {
          //   bottom: -4px;
          // }
          // @media screen and (max-width:767px) {
          //   bottom: -10px;
          // }
          height: 100%;
          width: 100%;
          background: linear-gradient(
            1deg,
            rgba(0, 0, 0, 0.73) 4.7%,
            #000 84.59%,
            #000 94.59%
          );
        }
      }
    }
    p {
      margin-bottom: 0;
      color: #fff;
      // font-family: $secondFont;
      font-size: clamp(1rem, 0.589rem + 0.8574vw, 1.5rem);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media screen and (max-width: 767px) {
        margin: auto;
      }
    }
    .socialIcons {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      gap: 42.29px;
      align-items: center;
      @media screen and (max-width: 992px) {
        display: none;
      }
      a {
        text-decoration: none;
      }
    }
  }
  .disclaimer {
    color: white;
    justify-content: center;
    display: flex;
    margin-top: 2vh;
    cursor: pointer;
  }

  .disclaimer-data {
    color: #fff;
    h1 {
      font-weight: 600;
      margin-bottom: 26px;
    }
    .topStrong {
      line-height: 100%;
      margin-bottom: 10px;
      opacity: 1;
      strong {
        font-size: 22px;
        font-weight: 700;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      opacity: 0.85;
      line-height: 150%;
    }
    ol {
      list-style: none;
      padding: 0;
      li {
        padding-left: 25px;
        position: relative;
        margin-bottom: 22px;
        &::before {
          position: absolute;
          content: "";
          height: 14px;
          width: 14px;
          right: 100%;
          top: 11.5px;
          transform: translateX(15px);
          background-image: url("./assets/listIcon.svg");
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
        strong {
          font-size: 18px;
          font-weight: 800;
          line-height: calc(100% + 20px);
        }
      }
    }
  }
  &.bg_remove {
    .App {
      background: none;
      &::before{
        content: none;
      }
      .customOuterContainer{
        &::after, &::before{
          content: none;
        }
      }
    }
  }
}
